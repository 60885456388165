import { Link } from "gatsby";
import * as React from "react";
import HeaderMenu from "./HeaderMenu/HeaderMenu";
import SidebarMenu from "./SidebarMenu/SidebarMenu";
import { Menu, Icon, Container, Sidebar, Grid, Header, Segment } from "semantic-ui-react";

import { } from "semantic-ui-react";
import BasicSegment from "./BasicSegment";
import "../css/styles.css";
import "../css/responsive.css";
import "../css/semantic.min.css";
import "prismjs/themes/prism-okaidia.css";
import { Provider } from "react-redux";
import { store } from "../store";

export const menuItems = [
  { name: "Начало", path: "/", exact: true, icon: "home", inverted: true },
  /*  { name: "За нас", path: "/about/", exact: true, icon: "info circle" }, */
  { name: "Услуги", path: "/services", exact: false, icon: "newspaper" },
  /* { name: "Контакти", path: "/contacts/", exact: true },*/
];

export interface LayoutProps {
  location: {
    pathname: string;
  };
  children: any;
}

const Layout = (props: LayoutProps) => {
  const { pathname } = props.location;
  const isHome = pathname === "/";

  return (
    <Provider store={store}>
      <Sidebar.Pushable as={BasicSegment}>
        <SidebarMenu
          Link={Link}
          pathname={pathname}
          items={menuItems}
          visible={false}
        />

        <Sidebar.Pusher style={{ minHeight: "100vh" }}>
          {/* Header */}

          {/* Render children pages */}
          <div style={{ paddingBottom: 60 }}>
            <Segment
              color="yellow"
              style={{ marginBottom: 0, zIndex: 1 }}
              basic
              padded="very"
              inverted
              secondary
            >
              <Container>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width="6">
                      <Menu.Item>
                        <Header as="h2" size="large">
                          <Icon name="lightbulb" />
                          <Header.Content>Електричарите</Header.Content>
                        </Header>
                      </Menu.Item>
                    </Grid.Column>
                    <Grid.Column width="10">
                      <Grid>
                        <Grid.Row>
                          {/*<Grid.Column width="7">
                      Работно време
                      <Header as="h4">
                        Понеделник - Петък:{" "}
                        <span style={{ float: "right" }}>10:00 - 19:00</span>
                        <br />
                        Събота и Неделя:{" "}
                        <span style={{ float: "right" }}>Почивен ден</span>
                      </Header>
                    </Grid.Column>
*/}
                          <Grid.Column width="8" className="mobile hidden">
                            <Header as="h2" size="small">
                              <Icon name="map" />
                              <Header.Content size="small">
                                <Header.Subheader>
                                  Район на обслужване
                          </Header.Subheader>
                          Банско, Разлог и региона
                        </Header.Content>
                            </Header>
                          </Grid.Column>
                          <Grid.Column width="8" className="mobile hidden">
                            <Header as="h2" size="small">
                              <Icon name="phone" />
                              <Header.Content size="small">
                                <Header.Subheader>Обадете се на</Header.Subheader>
                          +359 88 490 7045
                        </Header.Content>
                            </Header>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <HeaderMenu
                  Link={Link}
                  pathname={props.location.pathname}
                  items={menuItems}
                />
              </Container>

            </Segment>
            <main>{props.children}</main>
          </div>

          {/* Footer */}
          <BasicSegment
            inverted
            vertical
            style={{ position: "absolute", bottom: 0, width: "100%" }}
          >
            <Container textAlign="center">
              <p>Всички права запазени 2020</p>
            </Container>
          </BasicSegment>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </Provider>
  );
};

export default Layout;

export const withLayout = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
) =>
  class WithLayout extends React.Component<P & LayoutProps> {
    render() {
      return (
        <Layout location={this.props.location}>
          <WrappedComponent {...this.props} />
        </Layout>
      );
    }
  };
